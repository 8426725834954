import { AxiosError, AxiosResponse } from 'axios';
import { reactive, UnwrapNestedRefs } from 'vue';
import PubSub from 'pubsub-js';
import { debounce } from 'underscore';
import {
  DMPayinTransactionResponse,
  type MoneyProcessingCallbackData,
} from '@nsftx/systems-sdk';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { errorParser, axiosErrorParser } from '@/common/services/error-parser';
import { logService } from '@/common/services/logger';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import i18n from '@/plugins/i18n';
import BaseError from '@/common/errors/BaseError';
import HooksManager from '@/common/services/HooksManager';
import { narApiService } from '@/modules/nar';
import {
  attachPocketListeners,
  initPocket,
  payinCancel,
  transfer,
  payinRetry,
} from '@/modules/device-management';
import { initDmMoney, getMoneyInstance } from './dmAcceptorsService';
import * as narAcceptorsService from './narAcceptorsService';
import {
  type AcceptorHookParam,
  type AcceptorsStatus,
  type AcceptorType,
  type MoneyNotificationEvent,
  type AcceptorHookErrorResponse,
  AcceptorAction,
  TerminalAcceptorTransaction,
} from './types';
import * as apiService from './apiService';
import * as dmAcceptorsService from './dmAcceptorsService';

const { t } = i18n.global;

const acceptors: UnwrapNestedRefs<AcceptorsStatus> = reactive({
  bill: {
    status: false,
  },
  coin: {
    status: false,
  },
});
const whitelistedStartAcceptorsErrorMessages: string[] = [
  'acceptors-start-error-tbo',
  'acceptors-start-error-device-cash-deposit',
  'acceptors-start-error-oktopay',
  'acceptors-start-error-working-time',
  'acceptors-start-error-paper-low',
  'acceptors-start-error-paper-out',
  'acceptors-start-error-printer-not-selected',
];

let acceptorProcessingAmount: boolean;
let isCheckingInProgress = true;
let areAcceptorsInitialized: boolean;
let countOfTries: number = 0;
let promisesArray: Array<{ resolve: Function, reject: Function, actionName: AcceptorAction }> = [];
let runPromisesTimeout: number;
let initStateTimeout: number;

/**
 * Returns parsed acceptors errors as object with upstream_message and upstream_code,
 * where every code and message is divided with -
 * This is mostly for Graylog logs usage so we have clean error on the log.
 * @param {Array<AcceptorHookErrorResponse> | undefined} errors
 * @returns {Object}
 */
const parseAcceptorsHookError = (errors: Array<AcceptorHookErrorResponse> | undefined) => {
  const parsedErr = {
    upstream_code: '',
    upstream_message: '',
  };

  if (errors?.length) {
    errors.forEach((element) => {
      parsedErr.upstream_message += ` - ${element.message}`;
      parsedErr.upstream_code += ` - ${element.code}`;
    });
  }

  return parsedErr;
};

const executePromises = (): void => {
  const isAnyStopAttempt = !!promisesArray.find(
    (promise) => promise.actionName === AcceptorAction.STOP,
  );

  const error = new BaseError(
    'Acceptors on ready promise rejected',
    'T_ACCEPTORS_INIT_ON_READY_REJECTED',
    {
      context: {
        areAcceptorsInitialized,
        isAnyStopAttempt,
      },
    },
  );

  promisesArray.forEach((promise) => {
    if (areAcceptorsInitialized) {
      if (isAnyStopAttempt) {
        if (promise.actionName === AcceptorAction.STOP) {
          promise.resolve();
        } else {
          promise.reject(error);
        }
      } else {
        promise.resolve();
      }
    } else {
      promise.reject(error);
    }
  });

  promisesArray = [];
};

const validateStartAcceptors = (): Promise<void> => new Promise((resolve, reject) => {
  const params: AcceptorHookParam = {
    errorResponses: [],
  };
  HooksManager.run('BeforeAcceptorsStart', params).then(() => {
    if (params.errorResponses.length) {
      reject(params.errorResponses);
    } else {
      resolve();
    }
  }).catch((error) => {
    reject(error);
  });
});

const changeAcceptorsState = debounce((
  event: {
    resolve: Function,
    reject: Function,
  },
  acceptMoney: boolean,
  options?: {
    isOperator?: boolean
  },
  // eslint-disable-next-line consistent-return
) => {
  const { isDmApplicationRuntime } = useEnvironmentHandlerStore();
  const action = acceptMoney ? 'START' : 'STOP';

  if (isCheckingInProgress) {
    logService.debug('[AcceptorsService] Checking are acceptors initialized. Waiting ...', {
      code: 'T_ACCEPTORS_INITIALIZED_WAITING',
      details: {
        acceptMoney,
      },
    });
    window.setTimeout(() => {
      changeAcceptorsState(event, acceptMoney);
    }, 1000);
  } else {
    if (!isCheckingInProgress && !areAcceptorsInitialized) {
      logService.error(`[AcceptorsService] Acceptors are not initialized. Could not ${action} them.`, {
        code: 'T_ACCEPTORS_NOT_INITIALIZED',
        details: {
          acceptMoney,
        },
      });
      return event.reject({
        code: 'T_ACCEPTORS_NOT_INITIALIZED',
        message: 'Acceptors are not initialized.',
      });
    }

    if (isDmApplicationRuntime()) {
      dmAcceptorsService.changeAcceptorStatusAll(acceptMoney)
        .then(() => {
          logService.info(`[AcceptorsService] Message to ${action} acceptors successfully sent.`, {
            code: 'T_ACCEPTORS_CHANGE_ACTION_SUCCESS',
            details: {
              acceptMoney,
            },
          });
          event.resolve();
        }).catch((err) => event.reject(err));
    } else {
      narAcceptorsService.changeAcceptorsState(acceptMoney, options?.isOperator)
        .then(() => {
          logService.info(`[AcceptorsService] Message to ${action} acceptors successfully sent.`, {
            code: 'T_ACCEPTORS_CHANGE_ACTION_SUCCESS',
            details: {
              acceptMoney,
            },
          });
          event.resolve();
        }).catch((err) => {
          logService.error(`[AcceptorsService] Failed to send message to ${action} acceptors.`, {
            code: `T_ACCEPTORS_${action}_ERROR`,
            details: {
              acceptMoney,
            },
            ...axiosErrorParser.parseUpstream(err),
          });
          event.reject(err);
        });
    }
  }
}, 2000);

/**
   * @description This method calls all registered promises after acceptors
   * initialization check has been finished.
   * If acceptors are initilaized, all resolve promises will be executed. Otherwise, all reject
   * promises will be executed.
   */
const onInitializationFinished = (actionName: AcceptorAction): Promise<void> => {
  const promise = new Promise<void>((resolve, reject) => {
    promisesArray.push({
      resolve,
      reject,
      actionName,
    });
  });

  return promise;
};

/**
   * @description Blocks/blaclists some error messages when
   * acceptors fail to start.
   */
// eslint-disable-next-line max-len
const filterStartAcceptorsErrorMessages = (unfilteredErrorMessages: Array<AcceptorHookErrorResponse>): Array<AcceptorHookErrorResponse> => unfilteredErrorMessages
  .filter((errorMessage) => whitelistedStartAcceptorsErrorMessages.includes(errorMessage.id || ''));

/**
   * @description Starts bill and coin acceptors.
   * Note: this method will be resolved only after acceptors
   * initialization check have been finished.
   */
const startAcceptors = (): Promise<void> => {
  const notificationsStore = useNotificationsStore();

  return new Promise((resolve, reject) => {
    validateStartAcceptors().then(() => {
      changeAcceptorsState({ resolve, reject }, true);
    }).catch((errResponse) => {
      const filteredErrorMessages = filterStartAcceptorsErrorMessages(errResponse);
      filteredErrorMessages.forEach((err: AcceptorHookErrorResponse) => {
        notificationsStore.show({
          id: err.id,
          message: err.message,
          type: TNotificationTypeEnum.error,
        });
      });
      reject(errResponse);
    });
  });
};

/**
 * @description
 * This method will be resolved only after acceptors initilaization check have been finished.
 */
const stopAcceptors = (fromTbo?: boolean): Promise<void> => new Promise((resolve, reject) => {
  changeAcceptorsState({ resolve, reject }, false, { isOperator: fromTbo });
});

const detectInitialAcceptorsState = () => {
  if (!isCheckingInProgress) {
    executePromises();
    window.clearTimeout(runPromisesTimeout);
  } else {
    runPromisesTimeout = window.setTimeout(detectInitialAcceptorsState, 1100);
  }
};

const markAcceptorsInitilizationAsFailed = (): void => {
  const notificationsStore = useNotificationsStore();
  isCheckingInProgress = false;
  areAcceptorsInitialized = false;
  countOfTries = 0;
  window.clearTimeout(initStateTimeout);
  executePromises();
  notificationsStore.show({
    message: t('acceptors_not_initialized'),
    type: TNotificationTypeEnum.error,
  });
};

const startAcceptorsInitializationStateChecker = (): void => {
  isCheckingInProgress = true;
  countOfTries += 1;

  if (countOfTries > 60) {
    logService.error('[AcceptorsService] Acceptors are not initialized.', {
      code: 'T_ACCEPTORS_INITIALIZATION_ERROR',
    });

    markAcceptorsInitilizationAsFailed();
    return;
  }

  const { isDmApplicationRuntime } = useEnvironmentHandlerStore();

  if (isDmApplicationRuntime()) {
    initDmMoney().then(() => {
      isCheckingInProgress = false;
      window.clearTimeout(initStateTimeout);
      areAcceptorsInitialized = true;
      onInitializationFinished(AcceptorAction.START).then(() => {
        logService.info('[acceptorsService] Starting acceptors after acceptors has been initialized.', {
          code: 'T_DM_MONEY_INIT_START',
        });
        startAcceptors().catch((error) => {
          // error comes in this shape
          // {message=Acceptors could not be started. Pass card is not scanned.,
          // id=acceptors-start-error-passcards, code=T_BEFORE_ACCEPTORS_START_PASS_CARDS_ERROR}
          const errorFormatted: {
            errorDetails: Object, code?: string
          } = {
            errorDetails: error,
            code: 'T_DM_MONEY_INIT_START_ERR',
          };

          logService.warn(
            '[AcceptorsService] Acceptors initialization - start acceptors error detected.',
            errorFormatted,
          );
        });
      }).catch((err) => {
        logService.warn(
          '[AcceptorsService] Acceptors initialization - start acceptors error detected.',
          {
            error: err,
            code: 'T_DM_MONEY_INITIALIZATION_FAILED',
          },
        );
      });
    }).catch((err) => {
      logService.error('[AcceptorsService] Acceptors initialization route fails.', {
        code: 'T_DM_MONEY_INITIALIZATION_FAILS',
        ...errorParser.parseUpstream(err),
      });
      markAcceptorsInitilizationAsFailed();
    });

    // also init pocket
    initPocket()
      .then(() => {
        attachPocketListeners();
      }).catch((pocketErr) => {
        logService.error('[AcceptorsService] Pocket initialization failed.', {
          code: 'T_DM_POCKET_INITIALIZATION_FAILS',
          ...errorParser.parseUpstream(pocketErr),
        });
      });
  } else {
    narApiService.getAcceptorsInitializationStatus().then((response: AxiosResponse<{
      initialized: boolean
    }>) => {
      logService.info('[AcceptorsService] Acceptors initialization check route finished.', {
        details: {
          resData: response.data,
        },
        code: 'T_ACCEPTORS_INITIALIZATION_CHECK_FINISHED',
      });
      areAcceptorsInitialized = response?.status === 200 && response?.data?.initialized;

      if (areAcceptorsInitialized) {
        isCheckingInProgress = false;
        window.clearTimeout(initStateTimeout);
        onInitializationFinished(AcceptorAction.START).then(() => {
          logService.info('[acceptorsService] Starting acceptors...', {
            code: 'T_DCD_START_ACCEPTORS_REQUEST',
            upstream_code: 'AcceptorsService',
          });
          startAcceptors().catch((error) => {
            // error comes in this shape
            // {message=Acceptors could not be started. Pass card is not scanned.,
            // id=acceptors-start-error-passcards, code=T_BEFORE_ACCEPTORS_START_PASS_CARDS_ERROR}
            const errorFormatted: {
              errorDetails: Object, code?: string
            } = {
              errorDetails: error,
              code: 'T_ACCEPTORS_START_FAILED_ON_INIT',
            };

            logService.warn(
              '[AcceptorsService] Acceptors initialization - start acceptors error detected.',
              errorFormatted,
            );
          });
        }).catch((err) => {
          logService.warn(
            '[AcceptorsService] Acceptors initialization - start acceptors error detected.',
            {
              error: err,
              code: 'T_ACCEPTORS_INITIALIZATION_FAILED',
            },
          );
        });
      } else {
        initStateTimeout = window.setTimeout(startAcceptorsInitializationStateChecker, 1000);
      }
    }).catch((err) => {
      logService.error('[AcceptorsService] Acceptors initialization route fails.', {
        code: 'T_ACCEPTORS_INITIALIZATION_ROUTE_FAILS',
        ...errorParser.parseUpstream(err),
      });
      markAcceptorsInitilizationAsFailed();
    });
  }
};

const stopAcceptor = (acceptorsToStop: Array<AcceptorType>, withInfo: boolean): Promise<any[]> => {
  const promises: any = [];
  const notificationsStore = useNotificationsStore();

  acceptorsToStop.forEach((acceptor: AcceptorType) => {
    promises.push(
      apiService.stopAcceptor(acceptor).then(
        (response) => {
          if (response.status === 200) {
            if (withInfo) {
              notificationsStore.show(
                {
                  message: t(`${acceptor}_acc_stop_success`),
                  type: TNotificationTypeEnum.info,
                  delay: 3000,
                },
              );
            }

            acceptors[acceptor].status = false;
            localStorage.setItem(`acceptors.${acceptor}`, '0');
          } else {
            notificationsStore.show(
              {
                message: t('notifications.default_error_message'),
                type: TNotificationTypeEnum.warning,
                delay: 3000,
              },
            );
          }

          Promise.resolve(response);
        },
      ).catch((error: AxiosError) => {
        Promise.resolve(error);
      }),
    );
  });

  return Promise.all(promises);
};

function getAcceptorStatus(acceptor: AcceptorType): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const { isDmApplicationRuntime } = useEnvironmentHandlerStore();

    if (isDmApplicationRuntime()) {
      getMoneyInstance().getAcceptorStatus(acceptor).then((response) => {
        acceptors[acceptor].status = response.status.started;
        resolve(acceptors[acceptor].status);
      }).catch((err) => {
        logService.warn('[AcceptorsService] Get acceptor status failed', {
          ...errorParser.parseUpstream(err),
          code: 'T_DM_GET_ACCEPTOR_STATUS_FAILED',
          acceptor,
        });
        acceptors[acceptor].status = false;
        return reject(false);
      });
    } else {
      apiService.getAcceptorStatus(acceptor).then(
        (response) => {
          acceptors[acceptor].status = Number(response.data.code) === 1;
          resolve(acceptors[acceptor].status);
        },
      ).catch((error: AxiosError) => {
        logService.warn('[AcceptorsService] Get acceptor status failed', {
          ...errorParser.parseUpstream(error),
          code: 'T_GET_ACCEPTOR_STATUS_FAILED',
          acceptor,
        });
        acceptors[acceptor].status = false;
        return reject(false);
      });
    }
  });
}

const showMoneyNotification = (
  event: MoneyNotificationEvent | MoneyProcessingCallbackData,
): void => {
  let amount: number | string;

  const isMoneyNotificationEvent = (
    e: MoneyNotificationEvent | MoneyProcessingCallbackData,
  ): e is MoneyNotificationEvent => (e as MoneyNotificationEvent)?.message?.amount !== undefined;

  if (isMoneyNotificationEvent(event)) {
    amount = event.message.amount;
  } else {
    amount = event.amount;
  }

  if (event.type === 'moneyProcessing') {
    PubSub.publish('Loader.Start', 'updateBalance');

    if (acceptorProcessingAmount && amount) {
      PubSub.publish('Acceptor.Amount.Change', amount);
    }
  } else if (event.type === 'moneyProcessed') {
    // Add 0.5 second latency to overlap loaders just in case
    window.setTimeout(() => {
      PubSub.publish('Loader.Stop', 'updateBalance');
      if (acceptorProcessingAmount && amount) {
        PubSub.publish('Acceptor.Amount.Change', null);
      }
    }, 500);
  }
};

const init = () => {
  const gravitySettingsStore = useGravitySettingsStore();
  acceptorProcessingAmount = !!gravitySettingsStore.getModuleDataKeyValue('config', 'acceptorProcessingAmount');

  startAcceptorsInitializationStateChecker();
};

const cancelTransaction = async (id: string)
: Promise<TerminalAcceptorTransaction | DMPayinTransactionResponse> => {
  const { isDmApplicationRuntime } = useEnvironmentHandlerStore();
  if (!isDmApplicationRuntime()) {
    return apiService.cancelTransaction(id);
  }

  return payinCancel(id);
};

const retryTransaction = async (id: string) => {
  const { isDmApplicationRuntime } = useEnvironmentHandlerStore();
  if (!isDmApplicationRuntime()) {
    return apiService.retryTransaction(id);
  }

  return payinRetry(id);
};

const transferTerminalMoney = () => {
  const { isDmApplicationRuntime } = useEnvironmentHandlerStore();

  if (!isDmApplicationRuntime()) {
    // notify acceptors
    return apiService.transfer('bill');
  }

  return transfer();
};

export {
  init,
  acceptors,
  stopAcceptor,
  getAcceptorStatus,
  showMoneyNotification,
  startAcceptors,
  stopAcceptors,
  onInitializationFinished,
  parseAcceptorsHookError,
  detectInitialAcceptorsState,
  executePromises,
  validateStartAcceptors,
  cancelTransaction,
  retryTransaction,
  transferTerminalMoney,
};
