import { DmPrinter, DmPrinterState, type DmPrinterStatus } from '@nsftx/systems-sdk';
import i18n from '@/plugins/i18n';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { useENVStore } from '@/common/stores/env';
import { PrinterSpsStatus } from './interfaces/printerMessageData';
import { usePrinterStatusStore } from './printerStatusStore';
import { PrinterStatusCode, PrinterSpsStatusSeverity } from './enums';
import type { Printer, PrinterStatusResponse } from './types';

const { t } = i18n.global;

const SPS_STATUS_NAME = {
  paperLow: 'Paper low',
  paperOut: ['Paper out', 'Paper out while printing'],
};
const PRINT_SERVICE_IP_LS_KEY = 'settings.printServiceIp';

/**
 * Returns a damaged (error) status by printer severity level and can print status
 * if it's found in the status list,
 * otherwise returns undefined.
 */
const getPrinterDamagedStatus = (
  statusList: (PrinterSpsStatus | DmPrinterState)[],
  canPrint: boolean,
) => {
  if (!statusList.length) {
    return undefined;
  }

  let damagedStatus: PrinterSpsStatus | DmPrinterState | undefined;
  if (!canPrint) {
    damagedStatus = statusList.find(({ status, severity }) => (
      status === PrinterStatusCode.Error && severity === PrinterSpsStatusSeverity.Error
    ));
    return damagedStatus;
  }

  damagedStatus = statusList.find(({ status, severity }) => (
    status === PrinterStatusCode.Error && severity === PrinterSpsStatusSeverity.Error
  ));
  if (!damagedStatus) {
    damagedStatus = statusList.find(({ status, severity }) => (
      status === PrinterStatusCode.Error && severity === PrinterSpsStatusSeverity.Warning
    ));
  }

  return damagedStatus;
};

const getTranslatedDamagedStatusMessage = (damagedStatusName: string): string => {
  /**
   * a string where empty spaces and special characters are replaced with the underscore.
   */
  const damagedStatusNameKey = damagedStatusName.toLowerCase()
    .replace(/\s/g, '_')
    .replace(/[^\w\s]/gi, '_');
  const translationKey = `print.${damagedStatusNameKey}`;

  return t(translationKey);
};

const isSpsPaperLow = (damagedStatusName: string) => (
  damagedStatusName === SPS_STATUS_NAME.paperLow
);

const isSpsPaperOut = (damagedStatusName: string) => (
  SPS_STATUS_NAME.paperOut.includes(damagedStatusName)
);

const isDmPrinter = (printer: Printer | null): printer is DmPrinter => (
  !!(printer as DmPrinter)?.id
);

const getPrinterId = (printer: Printer | null) => {
  if (isDmPrinter(printer)) {
    return printer.id;
  }

  return printer?.Id || printer?.index;
};

const isDmPrinterStatus = (printerStatus: PrinterStatusResponse):
printerStatus is DmPrinterStatus => (
  (printerStatus as DmPrinterStatus).can_print !== undefined
);

const getSpsRequestUrl = () => {
  const envStore = useENVStore();
  const ip = localStorage.getItem(PRINT_SERVICE_IP_LS_KEY);

  return ip ? `http://${ip}:7007/` : envStore.data.settings.printService.spsUrl;
};

const showPrinterErrorStatus = () => {
  const { printerStatusData, isStatusPaperOut } = usePrinterStatusStore();
  const { getByKey } = useGravitySettingsStore();
  const notificationsStore = useNotificationsStore();

  const { printerWarningUiBlocking } = getByKey('config') || {};
  const printerStatusMessage = printerStatusData.message ? `${printerStatusData.message}.` : '';
  const message = `${printerStatusMessage} ${t('notifications.printer_money_actions_blocked')}`;
  const notificationConfig = {
    id: 'printer_error',
    type: TNotificationTypeEnum.error,
    uiBlocking: false,
    closeDisabled: false,
    delay: null,
    message,
  };

  if (printerWarningUiBlocking && isStatusPaperOut()) {
    notificationConfig.message = t('notifications.printer_paper_error');
    notificationConfig.uiBlocking = true;
    notificationConfig.closeDisabled = true;
  }

  notificationsStore.show(notificationConfig);
};

export {
  getPrinterDamagedStatus,
  getTranslatedDamagedStatusMessage,
  isSpsPaperLow,
  isSpsPaperOut,
  getPrinterId,
  isDmPrinterStatus,
  getSpsRequestUrl,
  showPrinterErrorStatus,
};
