import i18n from '@/plugins/i18n';
import BaseError from '@/common/errors/BaseError';
import HooksManager from '@/common/services/HooksManager';
import { logService } from '@/common/services/logger';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import { errorParser } from '@/common/services/error-parser';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import type { ScannerPrintConfigType } from '@/modules/tbo-options/device/types';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import { deviceManagementPrintService } from '@/modules/device-management';
import { getService } from '@/common/services/ngBridge';
import { TPrintErrorCodes } from '../TPrintErrorCodes';
import { SELECTED_PRINTER_LS_KEY, usePrinterStore } from '../printerStore';
import { usePrinterStatusStore } from '../printerStatusStore';
import { init as initPrintTemplateService } from './printTemplateService';
import {
  handlePaperWarningAndErrorStatusMessages,
  getIsTicketPayinOnPaperLowDisabled,
  handlePrinterStatusChange,
} from '../printerMessagesService';
import { showPrinterErrorStatus } from '../printerHelpers';
import {
  PrintDataAdditionalData,
  PrintDataInfo,
  PrintData,
  type PrintJobResponseSuccess,
  type PrintJobResponseError,
  type Printer,
  type PrintServicePrinter,
} from '../types';
import type { PrintJobResponseHandlerData } from '../interfaces/PrintJobResponseHandlerData';
import * as spsService from './spsService';

// Constants
const LOG_PREFIX = '[printService]';

const getPrinterStatus = async () => {
  const { getPrinterService } = usePrinterStore();

  return getPrinterService().getStatus();
};

const detectPrintService = () => {
  const { setPrinterService } = usePrinterStore();
  const { isDmApplicationRuntime } = useEnvironmentHandlerStore();
  const printerService = isDmApplicationRuntime()
    ? deviceManagementPrintService
    : spsService;

  setPrinterService(printerService);
};

const setActivePrinter = async () => {
  const { getPrinterService } = usePrinterStore();
  const printerData = localStorage.getItem(SELECTED_PRINTER_LS_KEY) as string;
  let printer: Printer | null;

  try {
    printer = printerData ? JSON.parse(printerData) : null;
  } catch (error) {
    logService.warn(`${LOG_PREFIX} Cannot parse local storage printer data`, {
      ...errorParser.parseUpstream(error),
      printerData,
      code: 'T_PRINT_SERVICE_SET_LS_PRINTER_PARSE_ERROR',
    });
    printer = null;
  }

  try {
    getPrinterService().init(printer as PrintServicePrinter)
      .then(() => {
        getPrinterStatus().catch(showPrinterErrorStatus);
      })
      .catch((err) => {
        logService.error(`${LOG_PREFIX} Print service initialization failed`, {
          ...errorParser.parseUpstream(err),
          selectedPrinter: printer,
          code: 'T_PRINT_SERVICE_INIT_ERROR',
        });
      });
  } catch (error) {
    logService.error(`${LOG_PREFIX} Get printer service failed`, {
      code: 'T_PRINT_SERVICE_INIT_ERROR',
      ...errorParser.parseUpstream(error),
    });
  }
};

const registerHooks = () => {
  const NgHooksManager = getService<{ getHook: Function }>('HooksManager');
  const beforeTicketPayinHook = NgHooksManager?.getHook('BeforeTicketPayin');

  beforeTicketPayinHook?.tapPromise({
    name: 'BeforeTicketPayin.printService',
    fn() {
      return new Promise((resolve, reject) => {
        getPrinterStatus()
          .then((printerStatus) => {
            const { isStatusPaperLow } = usePrinterStatusStore();

            if (getIsTicketPayinOnPaperLowDisabled() && isStatusPaperLow()) {
              const { t } = i18n.global;
              reject(new BaseError(
                t('printer_status_paper_low_ticket_payin_disabled'),
                'T_PAYIN_VALIDATION_PRINT_LOW_PAPER',
              ));
            }
            resolve(printerStatus);
          })
          .catch((error) => reject({
            ...error,
            message: error.message,
            code: 'T_PAYIN_VALIDATION_PRINT_STATUS_ERR',
          }));
      });
    },
  });
};

const saveSelectedPrinter = (printer: PrintServicePrinter) => {
  const { getPrinterService } = usePrinterStore();

  return getPrinterService().saveSelectedPrinter(printer);
};

const init = () => {
  detectPrintService();
  setActivePrinter();
  initPrintTemplateService();
  registerHooks();
  handlePaperWarningAndErrorStatusMessages();
  handlePrinterStatusChange();
};

const validatePrint = (printData: PrintData) => HooksManager.BeforePrint.promise(printData);

const handlePrintJobSuccessResponse = (
  response: PrintJobResponseSuccess,
  data: PrintJobResponseHandlerData,
) => {
  const { printerService } = usePrinterStore();
  const { getModuleDataKeyValue } = useGravitySettingsStore();
  const checkStatusAfterPrint = getModuleDataKeyValue<boolean>(
    'print',
    'checkStatusAfterPrint',
  );
  const { requestUuid, productDisplayId } = data;

  logService.info(`${LOG_PREFIX} Successfully printed.`, {
    request_id: requestUuid,
    product_displayid: productDisplayId,
    code: 'T_PRINT_SUCCESS',
  });

  if (checkStatusAfterPrint) {
    printerService?.getStatus().catch((error) => {
      const notificationsStore = useNotificationsStore();
      notificationsStore.closeNotificationWithId('printer_error');
      notificationsStore.closeNotificationWithId('printer_warning');
      notificationsStore.show({
        id: 'printer_error',
        type: TNotificationTypeEnum.error,
        message: errorParser.parseMessage(error),
        delay: 5000,
      });
    });
  }

  return Promise.resolve(response);
};

const handlePrintJobErrorResponse = (
  err: PrintJobResponseError,
  data: PrintJobResponseHandlerData,
) => {
  const { t } = i18n.global;
  const { requestUuid, productDisplayId, debugData } = data;
  const parsedError = errorParser.parseUpstream(err);
  const message = parsedError.upstream_code === '429'
    ? t('printer_queue_limit_reached')
    : t('notifications.print_not_finished');

  logService.error(`${LOG_PREFIX} Failed to print.`, {
    ...parsedError,
    debugData,
    code: TPrintErrorCodes.T_PRINT_ERROR,
    request_id: requestUuid,
    product_displayid: productDisplayId,
  });

  return Promise.reject(new BaseError(
    parsedError.upstream_message || message,
    TPrintErrorCodes.T_PRINT_ERROR,
    { cause: err },
  ));
};

const printJob = (info: PrintDataInfo, data: any, additionalData: PrintDataAdditionalData = {}) => {
  const { getPrinterService } = usePrinterStore();
  const { layout, layoutName, type } = info;
  const action = info.action || '';
  const productId = info.productId || '';
  // type + action is for voucher and reports while productId for games,
  // so we have product_displayid on graylog for each print action,
  // this can change in future once we drop NPS (it can be simplify)
  const productDisplayId = productId || (type + action);
  const requestUuid = additionalData?.clientPrintJobIdentifier?.uuid;
  const debugData = {
    type,
    action,
    layoutName,
    layout,
    data,
    additionalData,
  };
  const responseHandlerData = {
    requestUuid,
    productDisplayId,
    debugData,
  };

  return validatePrint({ info, data, additionalData }).then(() => {
    const printDataInfo = {
      layoutName: layoutName || (type + action),
      nameContainingProduct: type + productId + action,
      layout,
    };
    logService.info(`${LOG_PREFIX} Sending to print.`, {
      code: 'T_PRINT_SEND_TO_PRINT',
      request_id: requestUuid,
      product_displayid: productDisplayId,
    });

    return getPrinterService().printJob(printDataInfo, data, additionalData)
      .then((response) => (
        handlePrintJobSuccessResponse(response as PrintJobResponseSuccess, responseHandlerData)
      ));
  }).catch((error: PrintJobResponseError) => (
    handlePrintJobErrorResponse(error, responseHandlerData)
  ));
};

const performPrint = (printData: PrintData) => new Promise((resolve, reject) => {
  getPrinterStatus()
    .then(() => printJob(printData.info, printData.data, printData.additionalData)
      .then(() => {
        PubSub.publish('7T:PrintService.PrintSuccess', printData);
        return resolve(printData);
      })
      .catch((error) => {
        PubSub.publish('7T:PrintService.Error', {
          printData,
          reason: TPrintErrorCodes.T_PRINT_ERROR,
        });
        return reject({
          ...error,
          upstream_code: error?.upstream_code,
          upstream_message: error?.upstream_message,
          type: TPrintErrorCodes.T_PRINT_ERROR,
        });
      }))
    .catch((err) => {
      const { t } = i18n.global;
      PubSub.publish('7T:PrintService.Error', {
        printData,
        reason: TPrintErrorCodes.T_PRINTER_STATUS_ERROR,
      });
      return reject({
        ...err,
        upstream_code: err.code || err.data?.code || TPrintErrorCodes.T_PRINTER_STATUS_ERROR,
        upstream_message: err.data?.message || err.message
        || t('notifications.default_error_message'),
        type: TPrintErrorCodes.T_PRINTER_STATUS_ERROR,
      });
    });
});

const printScannerConfig = async (type: ScannerPrintConfigType) => {
  try {
    await printJob({ layoutName: 'scannerConfig' }, { type, action: 'start' });
    await printJob({ layoutName: 'scannerConfig' }, { type, action: type });
    await printJob({ layoutName: 'scannerConfig' }, { type, action: 'end' });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export {
  init,
  getPrinterStatus,
  printJob,
  performPrint,
  printScannerConfig,
  saveSelectedPrinter,
};
